var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('SfHeading',{directives:[{name:"e2e",rawName:"v-e2e",value:('heading-billing'),expression:"'heading-billing'"}],staticClass:"sf-heading--left sf-heading--no-underline title",attrs:{"level":3,"title":_vm.$t('Billing address')}}),_vm._v(" "),(_vm.hasSavedBillingAddress)?_c('SfHeading',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-heading'),expression:"'shipping-heading'"}],staticClass:"sf-heading--left sf-heading--no-underline form-subtitle",attrs:{"level":4,"title":_vm.$t('Select previously saved address')}}):_vm._e(),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleAddressSubmit(reset))}}},[_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('copy-address'),expression:"'copy-address'"}],staticClass:"form__element",attrs:{"selected":_vm.sameAsShipping,"label":_vm.$t('My billing and shipping address are the same'),"name":"copyShippingAddress"},on:{"change":function($event){return _vm.handleCheckSameAddress($event)}}}),_vm._v(" "),(_vm.sameAsShipping)?_c('div',{staticClass:"copy__shipping__addresses"},[_c('div',{staticClass:"copy__shipping__address"},[_c('div',{staticClass:"sf-address"},[_c('UserAddressDetails',{attrs:{"address":Object.assign({}, _vm.billingDetails,
              {region: { region_code: _vm.billingDetails.region }})},scopedSlots:_vm._u([{key:"country",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.shippingDetailsCountryName)+"\n            ")]},proxy:true}],null,true)})],1)])]):_vm._e(),_vm._v(" "),(!_vm.sameAsShipping && _vm.isAuthenticated && _vm.hasSavedBillingAddress)?_c('UserBillingAddresses',{directives:[{name:"e2e",rawName:"v-e2e",value:('billing-addresses'),expression:"'billing-addresses'"}],attrs:{"current-address-id":_vm.currentAddressId,"billing-addresses":_vm.addresses,"countries":_vm.countries},on:{"setCurrentAddress":function($event){return _vm.handleSetCurrentAddress($event)}},model:{value:(_vm.setAsDefault),callback:function ($$v) {_vm.setAsDefault=$$v},expression:"setAsDefault"}}):_vm._e(),_vm._v(" "),(!_vm.sameAsShipping && _vm.isAddNewAddressFormVisible)?_c('div',{staticClass:"form"},[(_vm.hasSavedBillingAddress)?_c('SfHeading',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-heading'),expression:"'shipping-heading'"}],staticClass:"sf-heading--left sf-heading--no-underline form-subtitle",attrs:{"level":4,"title":_vm.$t('Enter different address')}}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('firstName'),expression:"'firstName'"}],staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.firstname,"label":_vm.$t('First Name'),"name":"firstName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('firstname', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"lastname","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('lastName'),expression:"'lastName'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"value":_vm.billingDetails.lastname,"label":_vm.$t('Last Name'),"name":"lastName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('lastname', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"street","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('streetName'),expression:"'streetName'"}],staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.street,"label":_vm.$t('Street Name'),"name":"streetName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('street', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"apartment","rules":"required|min:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('apartment'),expression:"'apartment'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"value":_vm.billingDetails.apartment,"label":_vm.$t('House/Apartment number'),"name":"apartment","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('apartment', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"city","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('city'),expression:"'city'"}],staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.city,"label":_vm.$t('City'),"name":"city","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('city', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"state","rules":!_vm.regionInformation ? null : 'required|min:2',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(
            !_vm.billingDetails.country_code || _vm.regionInformation.length === 0
          )?_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('state'),expression:"'state'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"label":_vm.$t('State/Province'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":!_vm.billingDetails.country_code,"name":"state"},on:{"input":function($event){return _vm.changeBillingDetails('region', $event)}},model:{value:(_vm.billingDetails.region),callback:function ($$v) {_vm.$set(_vm.billingDetails, "region", $$v)},expression:"billingDetails.region"}}):_c('SfSelect',{directives:[{name:"e2e",rawName:"v-e2e",value:('state'),expression:"'state'"}],staticClass:"form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"label":_vm.$t('State/Province'),"name":"state","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('region', $event)}},model:{value:(_vm.billingDetails.region),callback:function ($$v) {_vm.$set(_vm.billingDetails, "region", $$v)},expression:"billingDetails.region"}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.regionInformation),function(regionOption){return _c('SfSelectOption',{key:regionOption.id,attrs:{"value":regionOption.abbreviation}},[_vm._v("\n            "+_vm._s(regionOption.label)+"\n          ")])})],2)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"country_code","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfSelect',{directives:[{name:"e2e",rawName:"v-e2e",value:('country'),expression:"'country'"}],staticClass:"form__element form__element--half form__select sf-select--underlined",attrs:{"value":_vm.billingDetails.country_code,"label":_vm.$t('Country'),"name":"country","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":_vm.changeCountry}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n            "+_vm._s(countryOption.label)+"\n          ")])})],2)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"postcode","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('zipcode'),expression:"'zipcode'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"value":_vm.billingDetails.postcode,"label":_vm.$t('Zip-code'),"name":"zipCode","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('postcode', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"telephone","rules":"required|min:8","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('phone'),expression:"'phone'"}],staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.telephone,"label":_vm.$t(
              'Phone number (We will only call you if there are problems with the order.)'
            ),"name":"phone","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('telephone', $event)}}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),(!_vm.sameAsShipping && !_vm.isAddNewAddressFormVisible)?_c('SfButton',{staticClass:"color-light form__action-button form__action-button--add-address",attrs:{"type":"submit"},on:{"click":_vm.handleAddNewAddressBtnClick}},[_vm._v("\n      "+_vm._s(_vm.$t('Add new address'))+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__action"},[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('continue-to-payment'),expression:"'continue-to-payment'"}],staticClass:"form__action-button",attrs:{"type":"submit","disabled":!_vm.canMoveForward}},[_vm._v("\n          "+_vm._s(_vm.$t('Continue to payment'))+"\n        ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"sf-button sf-button--underlined form__back-button smartphone-only",attrs:{"to":"localePath('/checkout/shipping')"}},[_vm._v("\n          "+_vm._s(_vm.$t('Go back'))+"\n        ")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }